import React, { useState } from 'react'
import axios from "axios";
import {captchaUrl} from '../config'
import {captchaRefreshUrl} from '../config'

export default (props) => {

  const [ newData, setNewData ] = useState(captchaUrl +"&"+ Math.random())

  const handleGetData = () => {

    axios({
       method: "GET",
       url: captchaRefreshUrl,
       data: newData
    })
     .then(response => {
       setNewData(response.data )
     })
     .catch(error => {
       console.log("err")
     })
  }

  return(
    <>
      {props.data && (
        <div className='col-sm-6 '>
          <img src={newData}
               alt='error'
               style={{width: 120, height: 50, borderRadius: 30}}
          />
        </div>
      )}
        <p className='getCode'
           onClick={handleGetData}
        >
           Get a new code
        </p>
    </>
  )
}
