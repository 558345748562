import React from 'react'
import './alert-msg.css'

export default (props) => {

  return(
    <div className='warning-screen'>
      <div className='warning-bg'></div>
      <div className='warning-bg-add d-flex flex-column align-items-center justify-content-start col-md-5 col-sm-8 col-10'>
        <div className='warning d-flex flex-column align-items-center justify-content-center'>
          <p>We are not able to create an account for you right now. </p>
          <button className='button-warning'
                  onClick={props.handleClickClose}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  )
}
