import React from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import "./login.css"

const validationSchemaLogin = Yup.object().shape({
  name: Yup.string()
    .required('Enter a name'),
  password: Yup.string()
    .required("Enter a password")
})

export default (props) => {

  return(
    <Formik
      initialValues = {{ name: "",
                         password: ""
                      }}
      onSubmit= { (values, actions) => { props.handleOnSubmit(values, actions)}}
      validationSchema={validationSchemaLogin}
    >
      {({ isSubmitting }) => (
        <Form id="fs-frm-log" noValidate className='col-md-12 p-0'>
          <div className='col-md-12 d-flex flex-column p-0'>

              <div className='col-md-12 p-2'>
                  <Field id="email-log"
                         type="text"
                         name="name"
                         className='input-row-log'
                         placeholder = 'Username' />
                  <ErrorMessage name="name"
                                render={msg => <div className="errorMsg"><p>{msg}</p></div>}/>
              </div>

              <div className='col-md-12 p-2'>
                <Field id="pass"
                       type="password"
                       name="password"
                       className='input-row-log'
                       placeholder = 'Password'
                />
                <ErrorMessage name="password"
                              render={msg => <div className="errorMsg"><p>{msg}</p></div>}/>
              </div>
          </div>

          <div className='col-md-10 col-xl-7 p-2 d-flex flex-row align-items-center'>
            <button type='submit'
                    className='input-row-login'
                    disabled={isSubmitting}>
                    SIGN IN
             </button>
             <div className='col-md-2 col-xl-5 p-2'>
               <div className='spinner'>{props.spinner}</div>
             </div>
          </div>

        </Form>
      )}
    </Formik>
  )
}
