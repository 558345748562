import React, { useState } from 'react';
import axios from "axios";
import { Link } from "react-router-dom"
import LoginForm from './login-form';
import PasswordAction from './pass-action'
import joinImage from './img/join-brand-image2.png';
import WarningMessage from './UI/warningMessage'
import useSpinner from './UI/use-spinner'
import {loginAction} from './config'

export default () => {
  const [serverState, setServerState] = useState()
  const [isOpen, setIsOpen] = useState(false)
  const [spinner, showSpinner, hideSpinner] = useSpinner()

  const serverResponse = (isSuccess, message) => {
    setServerState({isSuccess, message});
  }
  const handleClickClose = () => {
    setIsOpen(false)
  }
  const handleOnSubmit = (values, actions) => {
    showSpinner()
    axios({
     method: "POST",
     url: loginAction,
     data: values
    })
     .then(response => {
       hideSpinner()
       actions.setSubmitting(false)
       actions.resetForm()
       serverResponse(true, window.location.reload(true))
       setIsOpen(true)
     })
     .catch(error => {
       hideSpinner()
       actions.setSubmitting(false)
       setIsOpen(true)
       serverResponse(true,  <WarningMessage handleClickClose={handleClickClose} />)
       actions.resetForm()
     })
  }
  return (
      <div className='content-login d-flex flex-row'>

        <div className='col-md-7 d-flex align-items-center'>
          <div className="col-md-12 col-xl-8 content-entity">
            <h1>Sign in</h1>
            <div className='linkJoin'>
              <p>Not registered yet?</p>
              <Link to="/join"><span>Join now</span></Link>
            </div>
            <LoginForm handleOnSubmit={handleOnSubmit}
                       spinner={spinner}
              />
            <PasswordAction/>
          </div>
        </div>
        <div className='col-md-5 login-brand'>
          <img src={joinImage} className='login-brand-image' alt='brand'/>
        </div>

        { isOpen && serverState && (
          <div className="popup">
               {serverState.message}
           </div>)
       }

      </div>
  )
}
