import React, { useState } from 'react';
import axios from "axios";
import { Link } from "react-router-dom"
import ResetPassForm from './reset-pass-form';
import WarningReset from './/UI/warning-reset'
import useSpinner from './UI/use-spinner'
import {resetPass} from './config'


export default () => {

  const [spinner, showSpinner, hideSpinner] = useSpinner()
  const [serverState, setServerState] = useState()
  const [isOpen, setIsOpen] = useState(false)
  const [isVisible, setIsVisible] = useState(true)

  const serverResponse = (isSuccess, message) => {
    setServerState({isSuccess, message});
  }

  const handleOnSubmit = (values, actions) => {
    showSpinner()
    axios({
     method: "POST",
     url: resetPass,
     data: values
    })
     .then(response => {
       hideSpinner()
       actions.setSubmitting(false)
       actions.resetForm()
       serverResponse(true, setIsVisible(false))
     })
     .catch(error => {
       hideSpinner()
       actions.setSubmitting(false)
       setIsOpen(true)
       serverResponse(false, <WarningReset handleClickClose={() => setIsOpen(false)}/>)
       actions.resetForm()
     })
  }
  return (
      <div className='content-reset d-flex flex-row'>

      {isVisible ? (
        <div className='col-md-7 d-flex align-items-center'>
            <div className="content-entity col-md-12 col-xl-8">
              <h1>Set new password</h1>
              <div className='linkJoin'>
                <p>Please enter your password</p>
              </div>
              <ResetPassForm handleOnSubmit={handleOnSubmit}
                             spinner={spinner}
              />
            </div>
          </div>)
          : <div className='col-md-11 d-flex align-items-start'>
              <div className='succes-reset'>
                <div className='linkMember'>
                  <Link to="/"><span>SIGN IN</span></Link>
                </div>
                <hr />
                <p className='succesMsg'>Your password has been reset.</p>
              </div>
            </div>
        }

        <div className='col-md-5 login-brand'></div>

        {isOpen && serverState && (
        <div className="popup">
             {serverState.message}
         </div>)}

      </div>
  )
}
