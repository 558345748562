import React, {useState} from 'react'
import axios from "axios"
import { Link} from "react-router-dom"
import "./join.css"
import WarningJoin from './UI/warning-join'
import useWarningCaptch from './UI/use-warn-captch'
import {joinAction} from './config'
import JoinForm from './join-form'
import useCaptcha from './UI/use-refresh-captch'
import useSpinner from './UI/use-spinner'

export default () => {
  const [ warning, showWarningCaptch, hideWarningCaptch ] = useWarningCaptch()
  const [ serverState, setServerState ] = useState()
  const [ isOpen, setIsOpen ] = useState(false)
  const [ isVisible, setIsVisible ] = useState(true)
  const [ captcha, refreshCaptcha ] = useCaptcha();
  const [ spinner, showSpinner, hideSpinner ] = useSpinner()

  const serverResponse = (isSuccess, message) => {
    setServerState({isSuccess, message});
  }

  const handleOnSubmit = (values, actions) => {
    showSpinner()
    axios({
      method: "POST",
      url: joinAction,
      data: values
    })
      .then(response => {
        hideSpinner()
        refreshCaptcha()
        actions.setSubmitting(false);
        actions.resetForm();
        serverResponse(true, setIsVisible(false))
      })
      .catch(error => {
        if (error.response.data !== '') {
          refreshCaptcha()
          showWarningCaptch()
          hideSpinner()
          actions.setSubmitting(false)
        }else{
          actions.setSubmitting(false)
          setIsOpen(true)
          hideSpinner()
          hideWarningCaptch()
          serverResponse(false, <WarningJoin handleClickClose={() => setIsOpen(false)}/>)
          actions.resetForm()
        }
      });
  }
  return(

    <div className='content-join d-flex flex-row'>
      <div className='col-lg-10 col-xl-8 mb-4'>
        <div className="col-md-12">
          <div className='linkMember'>
            <Link to="/"><span>SIGN IN</span></Link>
          </div>
          <hr className='hr'></hr>
          {isVisible ? (
            <>
              <p className='join-info'>Enter your details below and you will get instant access to all marketing materials to get started.</p>
              <JoinForm handleOnSubmit={handleOnSubmit}
                        warning={ warning }
                        captcha={ captcha }
                        spinner={ spinner }
              />

            </>
          ): <p className='succesMsg'>Thank You! We will contact you shortly… </p>}

        </div>
      </div>
      <div className='col-md-5 join-brand'>
      </div>
      {isOpen && serverState && (
        <div className="popup">
             {serverState.message}
         </div>
       )}
    </div>
  )
}
