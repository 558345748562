import React, { useState } from 'react'


 const useWarningCaptch = props => {

  const [visible, setVisible] = useState(false)
  const showWarningCaptch = () => setVisible(true)
  const hideWarningCaptch = () => setVisible(false)
  const warning = visible ? ( <p className='warn-captcha'>Invalid captcha code.</p>) : null

  return [ warning, showWarningCaptch, hideWarningCaptch ]
}
export default useWarningCaptch
