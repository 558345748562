import React, { useState } from 'react';


function ConfirmSuccess() {
  const [isOpen, setIsOpen] = useState(true);
  return(

      <div className={isOpen ? 'success-confirm' : 'success-confirm-out'}>
        <p>Success!</p>
        <button onClick={() => {setIsOpen(!isOpen)}}>OK</button>
      </div>
  );
}

export default ConfirmSuccess;
