import React, {useState} from 'react'
import axios from "axios"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import {restorePasswordAction} from './config'
import "./login.css"

const validationSchema = Yup.object().shape({

email: Yup.string()
  .email('Enter valid email')
  .required('Required'),
})

export default function InputRowDetails(){

  const [width, setWidth] = useState(250)
  const [isOpen, setIsOpen] = useState(false)
  const [serverState, setServerState] = useState()

  const handleServerResponse = (ok, msg) => {
    setServerState({ok, msg});
  }
  const handleOnSubmit = (values, actions) => {
    axios({
      method: "POST",
      url: restorePasswordAction,
      data: values
    })
      .then(response => {
        actions.setSubmitting(false);
        actions.resetForm();
        handleServerResponse(true, succesMsg)
      })
      .catch(error => {
        actions.setSubmitting(false)
        handleServerResponse(false, errMsg)
        console.log("error...")
      });
  }

  const succesMsg = <div className='succesMsgEmail'>An email has been sent.</div>
  const errMsg = <div className='errMsgEmail'>Server not responding.</div>


  function handleClickDetails(){
    setWidth('100%')
    setTimeout(() => {
      setIsOpen(!isOpen)
    }, 600);
  }

  return(
      <>
      <div className='login-wrap pt-4'>
      <Formik
        initialValues={{email: ""}}
        onSubmit={handleOnSubmit}
        validationSchema={validationSchema}
      >

        {({ isSubmitting }) => {

            if(!isOpen) return null
            return(

              <Form id="fs-frm-det" noValidate>

                <Field id="email-d"
                       type="input"
                       name="email"
                       placeholder = 'Enter Email:'
                       className="input-row-email" />
                <ErrorMessage name="email"
                              placeholder = 'Enter Email:'
                              render={msg => <div className="errorMsg-details"><p>{msg}</p></div>}
                />

                <button type='submit'
                        className="input-row-email-submit"
                        disabled={isSubmitting}
                >
                 </button>

                 <div className='success-ok'>
                   {serverState && (
                     <div className={!serverState.ok ? succesMsg : null}>
                       {serverState.msg}
                     </div>
                   )}
                 </div>

              </Form>)
        }}

        </Formik>

        <button onClick={handleClickDetails}
                className="input-row-details"
                style = {{
                  width: width,
                  transition: '.4s'
                }}>
          Forgot your details?
        </button>

      </div>
      </>
    )

}
