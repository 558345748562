import React from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"
import "./login.css"

const validationSchemaLogin = Yup.object().shape({

  password: Yup.string()
      .required("Password field is necessary"),
  confirmPassword: Yup.string()
      .test('passwords-match', 'Passwords don`t match', function(value) {
      return this.parent.password === value;
      })
      .required("Confirm password field is necessary")
})

export default (props) => {

  return(
    <Formik
      initialValues = {{ password: "",
                         confirmPassword: ""
                      }}
      onSubmit= { (values, actions) => { props.handleOnSubmit(values, actions)  }}
      validationSchema={validationSchemaLogin}
    >
      {({ isSubmitting }) => (
        <Form id="fs-frm-log" noValidate className='col-md-12 p-0'>
          <div className='col-md-12 d-flex flex-column p-0'>

              <div className='col-md-12 p-2'>
                <Field id="pass"
                       type="password"
                       name="password"
                       className='input-row-log'
                       placeholder = 'New Password'
                />
                <ErrorMessage name="password"
                              render={msg => <div className="errorMsg"><p>{msg}</p></div>}/>
              </div>

              <div className='col-md-12 p-2'>
                <Field id="pass"
                       type="password"
                       name="confirmPassword"
                       className='input-row-log'
                       placeholder = 'Confirm New Password'
                />
                <ErrorMessage name="confirmPassword"
                              render={msg => <div className="errorMsg"><p>{msg}</p></div>}/>
              </div>
          </div>

          <div className='col-md-12 p-2 d-flex flex-row align-items-center'>
            <button type='submit'
                    className='input-row-login'
                    disabled={isSubmitting}>
                    RESET PASSWORD
             </button>
             <div className='col-md-2 col-xl-5 p-2'>
               <div className='spinner'>{props.spinner}</div>
             </div>
          </div>

        </Form>
      )}
    </Formik>
  )
}
