import React from 'react'
import maintenance from '../img/maintenance.svg'
import './alert-msg.css'

export default () => {
  return(
    <div className="error-page container d-flex flex-column justify-content-center align-items-center">
      <h1>Maintenance</h1>
      <p>Sorry, this page is not available at this moment. Please try again later.</p>
      <img  src={maintenance}
            style={{position: 'absolute',
                    zIndex: -1,
                    width: 250,
                    paddingBottom: 160}}
            alt=''/>
    </div>
  )
}
